<template>
    <div class="search_adventure p-2">
        <b-row no-gutters>
            <!-- search -->
            <b-col cols="12" sm="6" md="4" lg="3" xl="3" align="center" class="pr-2">
                <b-input-group size="sm">
                    <b-form-input
                        type="search"
                        v-model="text"
                        @keypress.enter="fireSearch()"
                        :placeholder="$t('search.search')"
                    ></b-form-input>
                    <b-input-group-append>
                            <b-button class="p-0" style="width: 30px; height: 30px" @click="fireSearch()">
                                <b-icon-search scale="0.8"></b-icon-search>
                            </b-button>
                    </b-input-group-append>
                    <!-- filter button -->
                    <b-button
                        size="sm"
                        class="ml-1"
                        @click="openModalSearchAdventure()"
                    >
                        <b-icon-card-checklist></b-icon-card-checklist>
                    </b-button>
                </b-input-group>
            </b-col>
            <!-- popular categories and types -->
            <b-col class="d-none d-sm-block" sm="6" md="8" lg="9" xl="9" style="overflow: hidden">
                <b-button
                    size="sm"
                    pill
                    variant="white"
                    class="border-primary mr-2 mb-2"
                    v-for="jozica in 20"
                >
                    <!--                            variant="white" --> <!--TODO onselect variant="secondary"-->
                    # {{jozica}} Food
                </b-button>
            </b-col>
        </b-row>
        <!-- modal Search Adventure -->
        <modal-search-adventure
            :component-key="componentKey"
            :modal-bus.sync="modalBus"
        >
        </modal-search-adventure>
    </div>
</template>

<script>
import Vue from "vue";
import ModalSearchAdventure from "@/components/_public/_adventure/modal/ModalSearchAdventure";

// @group ADVENTURE
export default {
    name: "SearchAdventure",
    components: {ModalSearchAdventure},
    props: {},
    data() {
        return {
            text: null,
            componentKey: null,
            // <-- modal SearchAdventure -->
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.componentKey = this.__randomKey();
    },
    methods: {
        /**
         * @vuese
         * Fire search on press enter or click button search
         */
        fireSearch() {
            // <-- search text -->
            let data = {
                text: this.text
            }
            // <--  -->
            this.$emit('search', data);
        },
        // <-- SearchAdventure -->
        openModalSearchAdventure() {
            this.modalBus.$emit('modalOpen', 0);
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
