<template>
    <div>
        <b-container fluid class=" p-0">
            <b-card
                class="mb-2 my-2 experience-card"
                no-body
                header-bg-variant="white"
                header-class="px-2 py-0"
                header-text-variant="secondary"
                body-text-variant="secondary"
                footer-class="p-0"
                footer-border-variant="transparent"
            >
                <!-- head -->
                <template #header>
                    <b-row @click="record.expand=!record.expand">
                        <!-- id, name -->
                        <b-col class="d-flex justify-content-start align-items-center font-weight-bold">
                            {{record.title}}
                        </b-col>
                        <!-- buttons -->
                        <b-col cols="3" class="d-flex justify-content-end align-items-baseline py-1"
                               style="min-width: 130px;">
                            <div class="d-flex justify-content-end mx-0">
                                <!-- button collapse/expand -->
                                <b-icon
                                    :icon="record.expand ? 'three-dots-vertical' : 'three-dots'"
                                    style="cursor: pointer;"
                                    scale="0.9"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <!-- body -->
                <b-card-body class="mx-1 p-0" v-show="record.expand">
                    <!-- collage pictures -->
                    <b-col class="destination-collage" cols="12">
                        <b-row no-gutters>
                            <b-img class="destination-img" no-body v-for="(picture, index) in getPictures"
                                   :src="picture.image.src_p"
                                   style="cursor: pointer"
                                   @click="openModalPicture(index)"
                            />
                        </b-row>
                    </b-col>
                </b-card-body>
            </b-card>
        </b-container>
        <!-- modal images -->
        <b-modal
            :id="`modal-sider-route-pictures.${record.componentKey}`"
            hide-footer
            size="lg"
        >
            <!-- carousel -->
            <b-carousel
                id="sider-route-pictures-carousel"
                v-if="getPictures !== null"
                v-model="slide"
                :interval="0"
                background="#ababab"
                class="img-container mt-1"
                controls
                fade
                indicators
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >
                <b-carousel-slide
                    v-for="picture in getPictures"
                    style="text-shadow: 0px 0px 2px #000; text-align: center;"
                    fade
                    background="white"
                >
                    <template #img>
                        <b-img
                            fluid
                            :src="picture.image.src"
                            class="rounded-0"
                            :style="{width: picture.modalImageSize.width, height: picture.modalImageSize.height}"
                        />
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "SiderRoutePictures",
    components: {},
    props: {
        record: {},
    },
    data() {
        return {
            slide: 0,
            imageWidthMax: 765,
            imageHeightMax: 600,
        }
    },
    mounted() {
    },
    methods: {
        // <-- carousel -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        // <-- modal -->
        openModalPicture(index) {
            this.slide = index;
            this.$bvModal.show(`modal-sider-route-pictures.${this.record.componentKey}`);
        },
    },
    computed: {
        getPictures() {
            if (this.__isNull(this.record.pictures)) {
                return null;
            }
            // let maxPicturesNumber = 8;
            // let picturesNumber = 4;
            // <-- destinations pictures -->
            let pictures = this.__clone(this.record.pictures);
            pictures.forEach(picture => {
                picture.image.src = this.__getImageSrc(picture.image.src);
                picture.image.src_p = this.__getImageSrc(picture.image.src_p);
                picture.modalSizeImageWidth = this.imageWidthMax;
                picture.modalImageSize = this.__imageSizeOptimum(this.imageWidthMax, this.imageHeightMax, picture.image.width, picture.image.height);
            });
            // <-- experiences pictures -->
            // let experiences = this.__clone(this.record.experiences);
            // let loop = true;
            // do {
            //     loop = false;
            //     experiences.forEach(experience => {
            //         experience.pictures.every(picture => {
            //             pictures.push({
            //                 image: {
            //                     src: this.__getImageSrc(picture.image.src),
            //                     src_p: this.__getImageSrc(picture.image.src_p)
            //                 }
            //             });
            //             experience.pictures.splice(0, 1);
            //             loop = experience.pictures.length > 0 ? true : loop;
            //             return false;
            //         });
            //     });
            //     loop = pictures.length >= maxPicturesNumber ? false : loop;
            // } while (loop)
            // <--  -->
            // return pictures.slice(0, picturesNumber);
            return pictures;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
