<template>
    <div style="height: 100%">
        <!-- toolbar -->
        <b-button-toolbar
            ref="refToolbar"
            v-resize @resize="layoutToolbarResize"
            class="mt-1 mb-2"
        >
            <b-button class="mr-1 mb-1" size="sm" :variant="mode === 'experiences' ? 'primary' : 'secondary'"
                      @click="switchMode('experiences')">{{$t('adventure.sider_experience.experiences')}}
                {{`(${experiences.length})`}}
            </b-button>
            <b-button class="mr-1 mb-1" size="sm" :variant="mode === 'adventures' ? 'primary' : 'secondary'"
                      @click="switchMode('adventures')">{{$t('adventure.sider_experience.adventures')}}
                ({{routes.length}})
            </b-button>
            <b-button class="mr-1 mb-1" size="sm" :variant="mode === 'bookings' ? 'primary' : 'secondary'"
                      :disabled="!bookingEnabled"
                      @click="switchMode('bookings')">
                {{$t('adventure.sider_experience.booked')}}
                ({{countBookings}})
                <!--                ({{routes.length}})-->
            </b-button>
        </b-button-toolbar>
        <!-- mode: experiences -->
        <div ref="refExperiences" class="overflow-auto" :style="{'height': getContentHeight}"
             v-show="mode==='experiences'">
            <card-experience
                class="d-block mb-2"
                v-for="(experience, index) in getExperiences"
                :ref="'refExperience_' + experience.id"
                :hot-link.sync="hotLink"
                :data="experience"
                @eventExperienceSelect="experienceSelect"
                @eventPopupOpenClose="experiencePopupOpenClose"
            ></card-experience>
        </div>
        <!-- mode: adventures -->
        <div class="overflow-auto" :style="{'height': getContentHeight}" v-show="mode==='adventures'">
            <sider-routes
                :p-routes="routes"
                :hot-link="hotLink"
                @eventShowRoute="eventShowRoute"
                @eventPopupOpenClose="eventPopupOpenClose"
                @eventRefreshBookings="eventSetCountBookings"
            ></sider-routes>
        </div>
        <!-- mode: bookings -->
        <div class="overflow-auto" :style="{'height': getContentHeight}" v-show="mode==='bookings' && bookingEnabled">
            <sider-bookings
                :mode-size="modeSize"
                :hot-link="hotLink"
                @eventSetCountBookings="eventSetCountBookings"
                @eventShowRoute="eventShowRoute"
                @eventShowBookingRoute="eventShowBookingRoute"
            />
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import CardExperience from "@/components/_public/_adventure/elements/CardExperience";
import CardSumAdventure from "@/components/_public/_adventure/modal/CardSumAdventure";
import SiderRoutes from "@/components/_public/_adventure/sider/SiderRoutes";
import SiderBookings from "@/components/_public/_adventure/SiderBooked/SiderBooked";

// @group ADVENTURE
export default {
    name: "SiderAdventure",
    components: {
        SiderBookings,
        SiderRoutes, CardSumAdventure, CardExperience, draggable
    },
    props: {
        /**
         * @vuese
         * Unique component key
         */
        parentComponentKey: {
            type: String,
            required: true
        },
        //Layout height
        layoutHeight: {
            type: Number,
            default: 600,
        },
        /**
         * Destinations points included in adventures
         */
        adventures: {
            type: Array,
            default() {
                return []
            }
        },
        /**
         * Available destinations points with experiences
         */
        destinations: {
            type: Array,
            default() {
                return []
            }
        },
        /**
         * Experiences included in destinations
         */
        experiences: {
            type: Array,
            default() {
                return []
            }
        },
        /**
         * Routes with experiences
         */
        routes: {
            type: Array,
            default() {
                return []
            }
        },
        // Hot link among Adventure, siderExperience and LeafletMap
        hotLink: {
            type: Object,
            default() {
                return {}
            }
        },
        // Mode size
        modeSize: {
            type: String,
            default: 'md-xl',
        },
    },
    data() {
        return {
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            layout: [],
            /**
             * @vuese
             * switcher between experience, adventure and create adventure.
             */
            mode: 'adventures', //'adventures, bookings',
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                countBookings: `api/v1/booking/countBookings`,
            },
            // <--  -->
            countBookingsNumber: 0,
        }
    },
    mounted() {
        this.$set(this.hotLink, 'mode', this.mode);

        switch (this.modeSize) {
            case 'xs-sm':
                this.loadCountBookings();
                break;
            case 'md-xl':
                this.loadCountBookings();
                break;
        }
    },
    methods: {
        /**
         * @vuese
         * Added layout data (width, height) for calculating page or component optimal size (height).
         * @arg event
         */
        layoutToolbarResize(e) {
            this.layout = this.__layoutAdd(this.layout, 'toolbar', e.detail.width, e.detail.height);
        },
        // <-- mode -->
        switchMode(mode) {
            if (this.mode === mode) {
                return;
            }
            // <--  -->
            this.mode = mode;
            let data = {
                modeSize: this.modeSize,
                mode: mode
            };
            this.$emit('eventSwitchMode', data);
        },
        // <-- experience -->
        experienceSelect(experienceId) {
            this.$set(this.hotLink, 'popupOpen', false);
            this.$emit('eventPopupOpenClose', this.modeSize);
            // <-- hotlink.destinationId != null -->
            this.experiences.forEach(experience => {
                this.hotLink.destinationId = null;
            });
            // <-- hotlink.destinationId === null -->
            if (this.hotLink.destinationId === null) {
                this.$set(this.hotLink, 'experienceId', this.hotLink.experienceId === experienceId ? null : experienceId);
            }
        },
        experiencePopupOpenClose(experienceId) {
            // <-- hotlink.destinationId != null -->
            this.experiences.forEach(experience => {
                this.hotLink.destinationId = null;
            });
            // <-- close if it is open -->
            if (this.hotLink.popupOpen && this.hotLink.experienceId === experienceId) {
                this.$set(this.hotLink, 'experienceId', null);
                this.$set(this.hotLink, 'popupOpen', false);
                this.$emit('eventPopupOpenClose', this.modeSize)
                return;
            }
            // <--  -->
            this.$set(this.hotLink, 'experienceId', experienceId);
            this.$set(this.hotLink, 'popupOpen', true);
            this.$emit('eventPopupOpenClose', this.modeSize)
        },
        eventShowRoute(data) {
            data.modeSize = this.modeSize;
            this.$emit('eventShowRoute', data);
        },
        // <-- booking -->
        eventShowBookingRoute(data) {
            data.modeSize = this.modeSize;
            this.$emit('eventShowBookingRoute', data);
        },

        /**
         * @vuese
         * Remove experience from adventure
         * @arg adventureKey
         */
        experienceRemove(adventureKey) {
            this.getAdventures.every((adventure, index) => {
                if (adventure.key === adventureKey) {
                    this.getAdventures.splice(index, 1);
                    return false;
                }
                return true;
            });
        },
        /**
         * @vuese
         * Move the slider to the first experience with the correct destinations id.
         * @arg destinationId
         */
        scrollToExperienceByDestination(destinationId) {
            // let offset = 0;
            // this.getExperiences.every(experience => {
            //     if (experience.destinationId === destinationId) {
            //         this.$refs.refExperiences.scrollTo(0, offset);
            //         return false;
            //     }
            //     offset += this.$refs['refExperience_' + experience.id][0].$el.offsetHeight;
            //     return true;
            // });
        },
        /**
         * @vuese
         * Move the slider to the experience.
         * @arg experienceId
         */
        scrollToExperience(experienceId) {
            let offset = 0;
            this.getExperiences.every(experience => {
                if (experience.id === experienceId) {
                    this.$refs.refExperiences.scrollTo(0, offset);
                    return false;
                }
                offset += this.$refs['refExperience_' + experience.id][0].$el.offsetHeight;
                return true;
            });
        },
        // <-- popup -->
        eventPopupOpenClose() {
            this.$emit('eventPopupOpenClose', this.modeSize)
        },
        // <-- bookings -->
        eventSetCountBookings(data){
            this.countBookings = data;
        },
        loadCountBookings() {
            if (!this.bookingEnabled) {
                this.countBookings = 0;
                return;
            }
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.countBookings, config)
                .then((response) => {
                    this.countBookingsNumber = response.data;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        }
    },
    computed: {
        /**
         * @vuese
         * Check if user login for mode bookings
         */
        bookingEnabled() {
            return this.$root.mx_isLogin;
        },
        countBookings: {
            get() {
                return this.countBookingsNumber;
            },
            set(value) {
                this.countBookingsNumber = value;
            }
        },
        /**
         * @vuese
         * Optimal height for content ofSiderExperience.
         * @type string
         */
        getContentHeight() {
            let value = this.layoutHeight - this.__layoutHeightCalculate(this.layout);
            return value + 'px';
        },
        /**
         * @vuese
         * Avoid mutating a prop directly
         * @type set/get
         */
        getAdventures: {
            get: function () {
                return this.adventures;
            },
            set: function (newValue) {
                // Update props adventures
                // @arg adventureId
                this.$emit('eventUpdateAdventures', newValue)
            }
        },
        /**
         * @vuese
         * Add select/unselect option to experiences
         */
        getExperiences() {
            let experiences = this.__clone(this.experiences);
            experiences.forEach(experience => {
                this.$set(experience, 'selected', false);
                if (this.hotLink.destinationId === experience.destinationId || this.hotLink.experienceId === experience.id)
                    this.$set(experience, 'selected', true);
            });
            // <--  -->
            return experiences;
        },
        /**
         * @vuese
         * Available routes
         */
        getRoutes() {
            return 'routes'
        },
    },
    filters: {},
    watch: {
        'hotLink': {
            handler: function (hotLink) {
                // <-- scroll to experience -->
                if (hotLink.experienceId > 0) {
                    this.scrollToExperience(hotLink.experienceId);
                    return;
                }
                this.scrollToExperience(hotLink.destinationId);
                // this.scrollToExperienceByDestination(hotLink.destinationId);
            },
            deep: true
        },
        'mode': {
            handler: function (value, oldValue) {
                this.$set(this.hotLink, 'mode', this.mode);
            },
            deep: true
        },
        '$root.mx_isLogin': {
            handler: function (value, oldValue) {
                switch (this.modeSize) {
                    case 'xs-sm':
                        this.loadCountBookings();
                        break;
                    case 'md-xl':
                        this.loadCountBookings();
                        break;
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
