<template>
    <b-container fluid class=" p-0">

        <b-card
            class="mb-2 my-2 experience-card"
            no-body
            header-bg-variant="white"
            header-class="px-2 py-0"
            header-text-variant="secondary"
            body-text-variant="secondary"
            footer-class="p-0"
            footer-border-variant="transparent"
        >
            <!-- head -->
            <template #header>
                <b-row @click="record.expand=!record.expand">
                    <!-- id, name -->
                    <b-col class="d-flex justify-content-start align-items-center font-weight-bold">
                        {{record.title}}
                    </b-col>
                    <!-- buttons -->
                    <b-col cols="3" class="d-flex justify-content-end align-items-baseline py-1"
                           style="min-width: 130px;">
                        <div class="d-flex justify-content-end mx-0">
                            <!-- button collapse/expand -->
                            <b-icon
                                :icon="record.expand ? 'three-dots-vertical' : 'three-dots'"
                                style="cursor: pointer;"
                                scale="0.9"
                            />
                        </div>
                    </b-col>
                </b-row>
            </template>
            <!-- body -->
            <b-card-body class="mx-0 p-0" v-show="record.expand">
                <!-- experiences -->
                <b-col class="destination-collage mt-2 p-0 " cols="12">
                    <b-card
                        v-for="(data, index) in getExperiences"
                        class="mb-2 experience-card shadow"
                        :class="data.selected ? 'experience-select' :'experiences'"
                        no-body
                        header-bg-variant="secondary"
                        header-class="p-2"
                        header-text-variant="white"
                        footer-class="p-0"
                        footer-border-variant="transparent"
                        @click="experienceSelect(data.route, data.destinationId, data.experienceId)"
                    >
                        <!-- header -->
                        <template #header>
                            <b-row>
                                <b-col>
                                    {{data.header}}
                                </b-col>
                                <b-col cols="2">
                                    <div class="float-right">
                                        #{{data.id}}
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                        <!-- body -->
                        <b-row no-gutters>
                            <!-- picture -->
                            <b-col class="experience-card-image" cols="4">
                                <!-- no image -->
                                <b-img v-if="__isNull(data.pictures.length)" class="rounded-0"
                                       src="@/assets/images/default.jpg"/>
                                <!-- carousel -->
                                <b-carousel
                                    id="experience-carousel"
                                    v-if="data.pictures !== null"
                                    v-model="slide"
                                    :interval="0"
                                    background="#ababab"
                                    class="img-container mt-1"
                                    controls
                                    fade
                                    indicators
                                    @sliding-start="onSlideStart"
                                    @sliding-end="onSlideEnd"
                                >
                                    <b-carousel-slide v-for="picture in data.pictures">
                                        <template #img>
                                            <b-img
                                                class="rounded-0"
                                                :src="picture.image.src"
                                                style="max-height: 158px"
                                            />
                                        </template>
                                    </b-carousel-slide>
                                </b-carousel>
                            </b-col>
                            <!-- content -->
                            <b-col cols="8" class="experience-card-text">
                                <!-- organisation type -->
                                <b-card-body class="px-2 py-1">
                                    <b-row no-gutters>
                                        <b-col cols="12" class="experience-card-body">
                                            <!-- experience name -->
                                            <b-card-title>{{data.name}}</b-card-title>
                                            <!-- subtitle -->
                                            <b-card-sub-title>{{data.subtitle}}</b-card-sub-title>
                                            <p class="text-secondary mt-3 mb-0">
                                                <b-icon-clock></b-icon-clock>
                                                <b> {{data.duration}}</b>
                                            </p>
                                            <!-- categories, types -->
                                            <b-card-text class="text-primary my-1" style="clear: both">
                                                            <span v-for="categoryTypes in getCategoriesTypes">
                                                                <!-- categories -->
                                                                <b-badge
                                                                    variant="secondary"
                                                                    style="font-size: 9px"
                                                                >
                                                                    {{categoryTypes.category}}
                                                                </b-badge>
                                                                <!-- types -->
                                                                <b-badge v-for="(type, index) in categoryTypes.types"
                                                                         variant="primary"
                                                                         style="font-size: 9px"
                                                                         :class="index === categoryTypes.types.length-1 ? 'ml-1 mr-2' : 'ml-1'"
                                                                >
                                                                    {{type}}
                                                                </b-badge>
                                                            </span>
                                            </b-card-text>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-col>
                        </b-row>

                        <!-- footer -->
                        <template #footer>
                            <!-- best adult price -->
                            <div style="height: 37px" class="bg-info-light p-1">
                                <div class="float-right">
                                    <b-button size="sm" @click.stop="popupHotOpenClose(data.route, data.experienceId)">
                                        <b-icon-geo-alt-fill/>
                                    </b-button>
                                </div>
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-card-body>
        </b-card>
    </b-container>
</template>

<script>
// @group ADVENTURE
import CardExperience from "@/components/_public/_adventure/elements/CardExperience";

export default {
    name: "SiderRouteExperiences",
    components: {CardExperience},
    props: {
        route: {},
        record: {},
        // Hot link among Adventure, siderExperience and LeafletMap
        hotLink: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            slide: 0,
        }
    },
    mounted() {
    },
    methods: {
        // <-- experience -->
        experienceSelect(route, destinationId, experienceId) {
            this.$set(this.hotLink, 'popupOpen', false);
            this.hotLink.destinationId = null;
            // <-- show rute if not -->
            if (this.hotLink.route === null || this.hotLink.route.id !== route.id) {
                this.$emit('eventShowRoute', route.id);
            }
            // <--  -->
            this.$set(this.hotLink, 'experienceId', this.hotLink.experienceId === experienceId ? null : experienceId);
        },
        // <-- carousel -->
        onSlideStart(slide) {
            // this.slide = true;
        },
        onSlideEnd(slide) {
            this.slide = false;
        },
        // <-- data -->
        getDuration(day, time) {
            // <-- remove leader 0 in time -->
            if (!this.__isNull(time) && time.substring(0, 1) === '0') {
                time = time.substring(1);
            }
            // <--  -->
            let duration = day > 0 ? day + ' ' + this.$tc('date_time.day', day === 1 ? 1 : 0).toLowerCase() : '';
            duration += time === null ? '' : ' ';
            duration += time === null ? '' : time + ' h';
            // <--  -->
            return duration;
        },
        getCategoriesTypes(experienceTypes) {
            // <-- categories -->
            let categories = [];
            experienceTypes.forEach(value => {
                categories.push(value.categoryName);
            });
            categories = this.__uniqueArray(categories);
            categories = this.__sortArray(categories);
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                experienceTypes.forEach(record => {
                    if (category === record.categoryName) {
                        types.push(record.name);
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <--  -->
            return categoriesTypes;
        },
        // <-- popup -->
        popupHotOpenClose(route, experienceId) {
            // <-- Show route if not -->
            if (this.hotLink.route === null || this.hotLink.route.id !== route.id) {
                this.$emit('eventShowRoute', route.id);
            }
            /**
             * @vuese
             * Fire when select popupOpen
             * Select experience, select marker in map and open marker popup.
             * @arg {routeId, experience.id}
             */
            this.$emit('eventPopupOpenClose', {routeId: route.id, experienceId: experienceId});
        }
    },
    computed: {
        /**
         * @vuese
         * Add select/unselect option to experiences
         */
        getExperiences() {
            let experiencesRaw = this.__clone(this.record.content);
            let array = [];
            experiencesRaw.forEach((experience, index) => {
                // <-- pictures -->
                let picturesRaw = this.__clone(experience.pictures);
                let pictures = [];
                picturesRaw.forEach(picture => {
                    pictures.push({
                        image: {
                            src: this.__getImageSrc(picture.image.src),
                            src_p: this.__getImageSrc(picture.image.src_p),
                        }

                    });
                });
                // <--  -->
                array.push(
                    {
                        selected: this.hotLink.destinationId === experience.destinationI_id || this.hotLink.experienceId === experience.experience_id,
                        route: this.route,
                        destinationId: experience.destination_id,
                        experienceId: experience.experience_id,
                        header: ++index + '. ' + experience.destination.name,
                        id: experience.id,
                        name: !this.__isNull(experience.locale.name) ? experience.locale.name : experience.meta.name,
                        subtitle: !this.__isNull(experience.locale.subtitle) ? experience.locale.subtitle : experience.meta.subtitle,
                        duration: this.getDuration(experience.content.duration.days, experience.content.duration.time),
                        pictures: pictures,
                        experienceTypes: this.getCategoriesTypes(experience.experienceTypes),
                        bestAdultPrice: experience.bestAdultPrice,
                        order: experience.route_experiences_order,
                    }
                )
            });
            // <-- sort -->
            array = array.sort((a, b) => {
                return a.order - b.order;
            });
            // <--  -->
            return array;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.experience-select {
    min-height: 80px;
    border: #6b8b30 2px solid;
    border-radius: 0.25rem;
    background-color: rgba(171, 208, 84, 0.5);
}

.card-footer {
    border-top: transparent !important;
}
</style>
