<template>
    <div class="mb-2" :style="{'height': __layoutCenterHeightCalculate.px}">
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <!-- prepareSearchData, filters -->
            <b-row no-gutters v-resize @resize="layoutSearchResize">
                <search-adventure
                    @search="prepareSearchData"
                ></search-adventure>
            </b-row>
            <!-- modeSize: xs-sm -->
            <div class="d-block d-md-none overflow-auto"
                 :style="{'height': getLayoutSplitpanesHeight.px}"
            >
                <!-- container for sider/map -->
                <b-row no-gutters style="position: relative;">
                    <!-- sider adventure -->
                    <b-col xs="12" sm="12" md="6" lg="4" v-show="!toggleSiderMap">
                        <sider-adventure
                            parent-component-key="sider_xs-sm"
                            mode-size="xs-sm"
                            :layout-height="getLayoutSplitpanesHeight.value"
                            :experiences="getExperiences"
                            :routes="routes"
                            :hot-link="hotLink"
                            @eventSwitchMode="eventSwitchMode"
                            @eventExperienceSelect="experienceSelect"
                            @eventUpdateAdventures="setAdventures"
                            @eventPopupOpenClose="eventPopupOpenClose"
                            @eventShowRoute="eventShowRoute"
                            @eventShowBookingRoute="eventShowBookingRoute"
                        ></sider-adventure>
                    </b-col>
                    <!-- leafletMap -->
                    <b-col xs="12" sm="12" md="6" lg="8" v-show="toggleSiderMap">
                        <leaflet-map
                            :map-height="getLayoutSplitpanesHeight.px"
                            :overlay-visible="overlay.visible"
                            :destinations="getDestinations"
                            :hot-link="hotLink"
                            :bus="busLeafletMap_xs_sm"
                            @data:reload="prepareSearchData"
                        ></leaflet-map>
                    </b-col>
                </b-row>
                <!-- container for button switch -->
                <div style="width: 100%; position: absolute; bottom: 60px; z-index: 1000"
                     class="text-center"
                >
                    <!-- button: toggle map -->
                    <b-button
                        pill
                        class="d-inline-block d-md-none"
                        @click="toggleSiderMap = !toggleSiderMap"
                    >
                        <span v-show="toggleSiderMap"> {{$t('adventure.sider_experience.toggle_map_hide')}}</span>
                        <span v-show="!toggleSiderMap">{{$t('adventure.sider_experience.toggle_map_show')}}</span>
                        <b-icon
                            class="ml-2"
                            :icon="toggleSiderMap ? 'map-fill' : 'map'"
                        ></b-icon>
                    </b-button>
                </div>
            </div>
            <!-- modeSize: md-xl  -->
            <div class=" d-none d-md-block">
                <!-- split panes -->
                <splitpanes class="default-theme">
                    <!-- sider adventure -->
                    <pane
                        min-size="20"
                        size="30"
                        max-size="50"
                        :style="{'height': getLayoutSplitpanesHeight.px}"
                    >
                        <sider-adventure
                            parent-component-key="sider_md_xl"
                            mode-size="md-xl"
                            :layout-height="getLayoutSplitpanesHeight.value"
                            :experiences="getExperiences"
                            :routes="routes"
                            :hot-link="hotLink"
                            @eventSwitchMode="eventSwitchMode"
                            @eventExperienceSelect="experienceSelect"
                            @eventUpdateAdventures="setAdventures"
                            @eventPopupOpenClose="eventPopupOpenClose"
                            @eventShowRoute="eventShowRoute"
                            @eventShowBookingRoute="eventShowBookingRoute"
                        ></sider-adventure>
                    </pane>
                    <!-- leaflet map -->
                    <pane>
                        <leaflet-map
                            :map-height="getLayoutSplitpanesHeight.px"
                            :overlay-visible="overlay.visible"
                            :destinations="getDestinations"
                            :hot-link="hotLink"
                            :bus="busLeafletMap_md_xl"
                            @data:reload="prepareSearchData"
                        ></leaflet-map>
                    </pane>
                </splitpanes>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import Vue from "vue";
import SearchAdventure from "@/components/_public/_adventure/SearchAdventure";
import SiderAdventure from "@/components/_public/_adventure/SiderAdventure";
import LeafletMap from "@/components/_public/_adventure/LeafletMap";

// @group ADVENTURE
export default {
    name: "Adventure",
    components: {SiderAdventure, LeafletMap, SearchAdventure},
    props: {},
    data() {
        return {
            /**
             * @vuese
             * Turn on/off overlay when process is busy.
             */
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            layout: [],
            /**
             * @vuese
             * Switch between Sider experiences, routes and map.
             * <br>Default is map.
             */
            toggleSiderMap: true,
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getAdventure: `api/v1/_public/get_adventure`,
            },
            destinations: [],
            routes: [],
            // <--  -->
            /**
             * @vuese
             * Hot link among Adventure, siderExperience and LeafletMap
             */
            hotLink: {
                mode: null,
                destinationId: null,
                experienceId: null,
                popupOpen: false,
                route: null,
                routing: null,
            },
            /**
             * @vuese
             * Bus for leafletMap xs_sm_xl events
             */
            busLeafletMap_xs_sm: new Vue(),
            /**
             * @vuese
             * Bus for leafletMap md_xl events
             */
            busLeafletMap_md_xl: new Vue(),
        }
    },
    mounted() {},
    methods: {
        /**
         * @vuese
         * Show/hide overlap when a process is busy
         * @arg boolean
         */
        showOverlay(show) {
            this.overlay.opacity = show ? 0.7 : 1;
            this.overlay.visible = show;
        },
        // @ignore <-- layout -->
        /**
         * @vuese
         * Added layout data (width, height) for calculating page or component optimal size (height).
         * @arg event
         */
        layoutSearchResize(e) {
            let layout = this.__layoutAdd(this.layout, 'search', e.detail.width, e.detail.height);
        },
        eventSwitchMode(data) {
            switch (data.modeSize) {
                case 'xs-sm':
                    this.busLeafletMap_xs_sm.$emit('eventSwitchMode', data.mode);
                    break;
                case 'md-xl':
                    this.busLeafletMap_md_xl.$emit('eventSwitchMode', data.mode);
                    break;
            }
        },
        // @ignore <-- base -->
        /**
         * @vuese
         * Prepare search data and load data from database.
         * @arg JSON
         */
        prepareSearchData(data) {
            this.loadDataFromBase();
        },
        /**
         * @vuese
         * Load data from database
         */
        loadDataFromBase() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getAdventure, config)
                .then((response) => {
                    this.$set(this, 'destinations', response.data.destinations);
                    this.$set(this, 'routes', response.data.routes);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        // <--  -->
        // <-- adventures -->
        setAdventures(adventures) {
            this.adventures = adventures;
        },
        // <-- experience -->
        // @deprecated
        // @vuese
        // @arg adventure.id
        experienceSelect(destinationId, experienceId) {
            let previous = true;
            let actual, selected;
            switch (experienceId === null) {
                // <-- selected destinations point on map -->
                case true:
                    this.destinations.forEach(destination => {
                        destination.experiences.forEach(experience => {
                            if (destinationId === destination.id) {
                                previous = experience.selected ? previous : false;
                            }
                        });
                    });
                    actual = !previous;
                    // <--  -->
                    this.destinations.forEach(destination => {
                        selected = destinationId === destination.id ? !previous : false;
                        this.$set(destination, 'selected', selected);
                        // <--  -->
                        destination.experiences.forEach(experience => {
                            selected = destinationId === destination.id ? !previous : false;
                            this.$set(experience, 'selected', selected);
                        });
                    });
                    break;
                // <-- selected experience in siderExperience -->
                default:
                    previous = false;
                    let previousSelected = true;
                    this.destinations.forEach(destination => {
                        destination.experiences.forEach(experience => {
                            if (destinationId === destination.id) {
                                switch (experience.id === experienceId) {
                                    case true:
                                        previousSelected = experience.selected === undefined ? false : experience.selected;
                                        break;
                                    default:
                                        previous = experience.selected ? experience.selected : previous;
                                        break;
                                }
                            }
                        });
                    });
                    actual = previous || !previousSelected;
                    // <--  -->
                    this.destinations.forEach(destination => {
                        selected = destinationId === destination.id ? actual : false;
                        this.$set(destination, 'selected', selected);
                        // <--  -->
                        destination.experiences.forEach(experience => {
                            selected = experienceId === experience.id ? actual : false;
                            this.$set(experience, 'selected', selected);
                        });
                    });
                    break;
            }
        },
        // <-- popup -->
        eventPopupOpenClose(modeSize) {
            switch (modeSize) {
                case 'xs-sm':
                    this.toggleSiderMap = true;
                    this.busLeafletMap_xs_sm.$emit('eventPopupOpenClose');
                    break;
                case 'md-xl':
                    this.busLeafletMap_md_xl.$emit('eventPopupOpenClose');
                    break;
            }
        },
        // <-- routes -->
        eventShowRoute(data) {
            let modeSize = this.__clone(data.modeSize);
            delete data.modeSize;
            // <--  -->
            switch (modeSize) {
                case 'xs-sm':
                    this.toggleSiderMap = true;
                    this.busLeafletMap_xs_sm.$emit('eventShowRoute', data);
                    break;
                case 'md-xl':
                    this.busLeafletMap_md_xl.$emit('eventShowRoute', data);
                    break;
            }
        },
        // <-- booking -->
        eventShowBookingRoute(data) {
            let modeSize = this.__clone(data.modeSize);
            this.$set(this, 'destinations', data.destinations);
            // <--  -->
            switch (modeSize) {
                case 'xs-sm':
                    this.toggleSiderMap = true;
                    this.busLeafletMap_xs_sm.$emit('eventShowRoute', data.routeName);
                    break;
                case 'md-xl':
                    this.busLeafletMap_md_xl.$emit('eventShowRoute', data.routeName);
                    break;
            }
        },
        // <-- data -->
        getExperienceHeader(destination) {
            // <-- destinations header -->
            let destinationName = !this.__isNull(destination.locale.name) ? destination.locale.name : destination.meta.name
            let destinationPlace = destination.address.city;
            let destinationState = destination.address.state;
            let destinationContinent = destination.address.continent;
            return `${destinationName}, ${destinationPlace}, ${destinationState}`;
            // return `${destinationName}, ${destinationPlace}, ${destinationState}, ${destinationContinent}`;
        },
        getDuration(day, time) {
            // <-- remove leader 0 in time -->
            if (!this.__isNull(time) && time.substring(0, 1) === '0') {
                time = time.substring(1);
            }
            // <--  -->
            let duration = day > 0 ? day + ' ' + this.$tc('date_time.day', day === 1 ? 1 : 0).toLowerCase() : '';
            duration += time === null ? '' : ' ';
            duration += time === null ? '' : time + ' h';
            // <--  -->
            return duration;
        },
        getCategoriesTypes(experienceTypes) {
            // <-- categories -->
            let categories = [];
            experienceTypes.forEach(value => {
                categories.push(value.categoryName);
            });
            categories = this.__uniqueArray(categories);
            categories = this.__sortArray(categories);
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                experienceTypes.forEach(record => {
                    if (category === record.categoryName) {
                        types.push(record.name);
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <--  -->
            return categoriesTypes;
        },
    },
    computed: {
        // @ignore <-- layout -->
        /**
         * @vuese
         * Optimal height for SiderExperience and map components.
         * @type object
         */
        getLayoutSplitpanesHeight() {
            let value = this.__layoutCenterHeightCalculate.value - this.__layoutHeightCalculate(this.layout);
            return {
                value: value,
                px: value + 'px'
            };
        },
        // @ignore <-- data -->
        /**
         * @vuese
         * Destinations data to show in map
         * @type []
         */
        getDestinations() {
            if (this.__isNull(this.destinations)) {
                return [];
            }
            // <--  -->
            let destinations = [];
            let name, subtitle;
            this.destinations.forEach(destination => {
                // <-- name -->
                name = destination.meta.name;
                name = this.__isNull(destination.locale) || this.__isNull(destination.locale.name) ? name : destination.locale.name;
                // <-- subtitle -->
                subtitle = destination.meta.subtitle;
                subtitle = this.__isNull(destination.locale) || this.__isNull(destination.locale.subtitle) ? subtitle : destination.locale.subtitle;
                // <--  -->
                destinations.push(
                    {
                        selected: destination.selected,
                        id: destination.id,
                        title: name,
                        subtitle: subtitle,
                        latlng: {
                            lat: destination.content.coordinates.latitude,
                            lng: destination.content.coordinates.longitude
                        },
                        experiences: destination.experiences
                    }
                );
            });
            // <--  -->
            return destinations;
        },
        /**
         * @vuese
         * Experiences data to show in SiderExperience
         * @type []
         */
        getExperiences() {
            let experiences = [];
            let index = 0;
            this.destinations.forEach(destination => {
                destination.experiences.forEach(experience => {
                    // <-- destinations pictures -->
                    let pictures = experience.pictures;
                    pictures.forEach(picture => {
                        picture.image.src = this.__getImageSrc(picture.image.src);
                        picture.image.src_p = this.__getImageSrc(picture.image.src_p);
                    });
                    experiences.push(
                        {
                            selected: experience.selected,
                            destinationId: destination.id,
                            header: ++index + '. ' + this.getExperienceHeader(destination),
                            id: experience.id,
                            name: !this.__isNull(experience.locale.name) ? experience.locale.name : experience.meta.name,
                            subtitle: !this.__isNull(experience.locale.subtitle) ? experience.locale.subtitle : experience.meta.subtitle,
                            duration: this.getDuration(experience.content.duration.days, experience.content.duration.time),
                            pictures: pictures,
                            experienceTypes: this.getCategoriesTypes(experience.experienceTypes),
                            bestAdultPrice: experience.bestAdultPrice,
                        }
                    )
                });
            });
            // <--  -->
            return experiences;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
