<template>
    <b-container fluid class=" p-0">
        <b-card
            class="mb-2 my-2 experience-card"
            no-body
            header-bg-variant="white"
            header-class="px-2 py-0"
            header-text-variant="secondary"
            body-text-variant="secondary"
            footer-class="p-0"
            footer-border-variant="transparent"
        >
            <!-- head -->
            <template #header>
                <b-row @click="record.expand=!record.expand">
                    <!-- name -->
                    <b-col class="d-flex justify-content-start align-items-center font-weight-bold">
                        {{record.title}}
                    </b-col>
                    <!-- buttons -->
                    <b-col cols="3" class="d-flex justify-content-end align-items-baseline py-1"
                           style="min-width: 130px;">
                        <div class="d-flex justify-content-end mx-0">
                            <!-- button collapse/expand -->
                            <b-icon
                                :icon="record.expand ? 'three-dots-vertical' : 'three-dots'"
                                style="cursor: pointer;"
                                scale="0.9"
                            />
                        </div>
                    </b-col>
                </b-row>
            </template>
            <!-- body -->
            <b-card-body class="mt-2 mx-1 p-0" v-show="record.expand">
                <!--                <div class="editor-text" v-html="record.content"/>-->
                <div v-for="item in record.content">
                    <div>
                        <b>{{__convertBaseDateToLocaleDateString(item.departureDate)}}</b> -
                        {{__convertBaseDateToLocaleDateString(item.arrivalDate)}}
                        (#Nočitve#: {{item.durationDays}})
                    </div>
                    <div class="editor-text" v-html="item.locale.notes"/>
                </div>

            </b-card-body>
        </b-card>
    </b-container>
</template>

<script>
export default {
    name: "SiderRouteTravelDates",
    components: {},
    props: {
        record: {},
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
