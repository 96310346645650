<template>
    <b-container fluid class=" p-0">
        <b-card
            class="mb-2 my-2 experience-card"
            no-body
            header-bg-variant="white"
            header-class="px-2 py-0"
            header-text-variant="secondary"
            body-text-variant="secondary"
            footer-class="p-0"
            footer-border-variant="transparent"
        >
            <!-- head -->
            <template #header>
                <b-row @click="record.expand=!record.expand">
                    <!-- name -->
                    <b-col class="d-flex justify-content-start align-items-center font-weight-bold">
                        {{record.title}}
                    </b-col>
                    <!-- buttons -->
                    <b-col cols="3" class="d-flex justify-content-end align-items-baseline py-1"
                           style="min-width: 130px;">
                        <div class="d-flex justify-content-end mx-0">
                            <!-- button collapse/expand -->
                            <b-icon
                                :icon="record.expand ? 'three-dots-vertical' : 'three-dots'"
                                style="cursor: pointer;"
                                scale="0.9"
                            />
                        </div>
                    </b-col>
                </b-row>
            </template>
            <!-- body -->
            <b-card-body class="mx-1 mt-1 p-0" v-show="record.expand">
                <div v-for="item in getItems">
                    <!-- name, price -->
                    <div v-if="!__isNull(item.name)" style="min-width: 50px">
                        <b>{{$tc('form.name', 1)}}</b>: {{item.name}}
                    </div>
                    <div v-if="!__isNull(item.price)" style="min-width: 50px"><b>{{$tc('adventure.price', 0)}}</b>:
                        {{item.price}}
                    </div>
                    <!-- notes -->
                    <div class="mt-1 editor-text" v-if="!__isNull(item.description)" v-html="item.description"></div>
                </div>
            </b-card-body>
        </b-card>
    </b-container>
</template>

<script>
export default {
    name: "SiderRouteInsurance",
    components: {},
    props: {
        record: {},
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        getItems(){
            let items = [];
            if(this.__isNull(this.record.content)){
                return items;
            }
            // <--  -->
            let name;
            this.record.content.forEach(item => {
                // <-- name -->
                name = item.meta.name;
                name = this.__isNull(item.locale) || this.__isNull(item.locale.name) ? name : item.locale.name;
                items.push(
                    {
                        key: item.key,
                        name: name,
                        price: this.__getDecimalLocale(item.price) + ' ' + this.__getTenantCurrency,
                        description: item.locale.description,
                    }
                );
            });

            // <--  -->
            return items;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
