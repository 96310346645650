<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-card
                v-for="(record, index) in bookings"
                class="mb-2 my-2 mr-1 experience-card temp border"
                no-body
                :header-bg-variant="getBackgroundVariant(index)"
                header-class="px-2 py-0"
                :header-text-variant="index % 2=== 0 ? 'white' : 'dark'"
                body-text-variant="secondary"
                footer-class="p-0"
                footer-border-variant="transparent"
            >
                <!-- head -->
                <template #header>
                    <b-row @click="record.expand=!record.expand">
                        <!-- id, name -->
                        <b-col class="d-flex justify-content-start align-items-center">
                            {{record.route.name}}
                        </b-col>
                        <!-- buttons -->
                        <b-col cols="3" class="d-flex justify-content-end align-items-baseline py-1"
                               style="min-width: 130px;">
                            <div class="d-flex justify-content-end mx-0">
                                <!-- button show route -->
<!--                                <b-button-->
<!--                                    class="py-0 button-header p-0 mr-1"-->
<!--                                    style="width: 35px"-->
<!--                                    @click.stop="showRoute(record)"-->
<!--                                >-->
<!--                                    <b-icon-pin-map scale="0.7"/>-->
<!--                                </b-button>-->
                                <!-- button delete -->
                                <b-button
                                    class="py-0 button-header p-0 mr-1"
                                    variant="danger"
                                    style="width: 35px"
                                    @click.stop="recordDelete(record)"
                                >
                                    <b-icon-trash scale="0.8"/>
                                </b-button>
                                <!-- button collapse/expand -->
                                <b-button
                                    class="py-0 button-header p-0"
                                    style="width: 35px"
                                    @click.stop="record.expand=!record.expand"
                                >
                                    <b-icon
                                        :icon="record.expand ? 'three-dots-vertical' : 'three-dots'"
                                        scale="0.9"
                                    />
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <!-- body -->
                <b-card-body class="px-1 py-1 bg-info-light" v-show="record.expand">
                    <!-- id, stamp -->
<!--                    <b-row class="mb-2">
                        <b-col cols="12">
                            id: {{record.id}} <span style="font-size: smaller">({{record.stamp}})</span>
                        </b-col>
                    </b-row>-->
                    <!-- departure  -->
                    <b-row class="mb-0 mt-2">
                        <b-col cols="12">
                            <div class="text-secondary text-haeder">{{$tc('adventure.departure', 1)}}
                            </div>
                        </b-col>
                    </b-row>
                    <!-- departure date, time -->
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <b>{{$t('adventure.route.departure_date')}}</b>:
                            {{__convertBaseDateToLocaleDateString(record.content.departureDate)}}
                        </b-col>
                        <b-col cols="12">
                            <b>{{$t('adventure.route.departure_time')}}</b>: {{record.content.departureTime}}
                        </b-col>
                    </b-row>
                    <!-- arrival date, time -->
                    <b-row class="mb-2"
                           v-if="!__isNull(record.content.arrivalDate) || !__isNull(record.content.arrivalTime)">
                        <b-col cols="12" v-if="!__isNull(record.content.arrivalDate)">
                            <b>{{$t('adventure.route.arrival_date')}}</b>:
                            {{__convertBaseDateToLocaleDateString(record.content.arrivalDate)}}
                        </b-col>
                        <b-col cols="12" v-if="!__isNull(record.content.arrivalTime)">
                            <b>{{$t('adventure.route.arrival_time')}}</b>: {{record.content.arrivalTime}}
                        </b-col>
                    </b-row>
                    <!-- guests -->
                    <b-row class="mb-0 mt-2">
                        <b-col cols="12">
                            <div class="text-secondary text-haeder">{{$t('adventure.experience.guests')}}
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-0" v-for="key in Object.keys(record.content.guests)">
                        <b-col cols="12" v-if="record.content.guests[key] > 0">
                            <b>{{$tc(`adventure.experience.${key}`, 1)}}</b>: {{record.content.guests[key]}}
                        </b-col>
                    </b-row>

                    <!-- button -->
                    <b-form-group class="text-center mb-1 mt-2">
                        <b-button
                            :variant="getBackgroundVariant(index)"
                            @click="__gotoDestinations(record.stamp)"
                        >
                            {{$t('adventure.route.lets_go')}}
                            <b-icon-emoji-sunglasses scale="0.8"/>
                        </b-button>
                    </b-form-group>

                </b-card-body>


                <!-- footer -->
<!--                <template #footer>-->
<!--                    &lt;!&ndash; best adult price &ndash;&gt;-->
<!--                    <div v-show="record.expand" style="height: 37px" class="bg-info-light p-1">-->
<!--                        {{record.expand}} BOOKING-->
<!--                        &lt;!&ndash;                    <div class="float-right">-->
<!--                                                <div class="mb-0 d-inline mr-3 font-italic h5 text-secondary">-->
<!--                                                    Let's do it-->
<!--                                                    {{$t('adventure.sider_experience.best_adult_price')}}:-->
<!--                                                    &lt;!&ndash;                            <b>{{getBestAdultPrice}}</b>&ndash;&gt;-->
<!--                                                    999,66 €-->
<!--                                                </div>-->
<!--                                            </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                </template>-->
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

export default {
    name: "SiderBooked",
    components: {},
    props: {
        // Hot link among Adventure, siderExperience and LeafletMap
        hotLink: {
            type: Object,
            default() {
                return {}
            }
        },
        // Mode size
        modeSize: {
            type: String,
            default: 'md-xl',
        },
    },
    data() {
        return {
            /**
             * @vuese
             * Turn on/off overlay when process is busy.
             */
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                main: `api/v1/booking`,
            },
            bookings: [],
            route: {},
        }
    },
    mounted() {
        if (this.bookings.length === 0) {
            switch (this.modeSize) {
                case 'xs-sm':
                    this.loadDataFromBase();
                    break;
                case 'md-xl':
                    this.loadDataFromBase();
                    break;
            }
        }
    },
    methods: {
        /**
         * @vuese
         * Show/hide overlap when a process is busy
         * @arg boolean
         */
        showOverlay(show) {
            this.overlay.opacity = show ? 0.7 : 1;
            this.overlay.visible = show;
        },
        getBackgroundVariant(index) {
            return index % 2 === 0 ? 'secondary' : 'primary';
        },
        // <--  -->
        showRoute(record) {
            if (record === null || !this.__isLogin()) {
                return;
            }
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.main + `/getBookingRoute/${record.route.id}`, config)
                .then((response) => {
                    this.route = response.data;
                    let data = {
                        routeName: this.route.locale.name,
                        destinations: this.getDestinations
                    }
                    this.$emit('eventShowBookingRoute', data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
            // <--  -->
            // this.hotLink.route = route;
            // this.hotLink.routing = routes;
        },
        // <--  -->
        recordDelete(record) {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('form.delete_message.pre')} ${this.$tc(this.localeKey, 2)} `,
                    h('strong', record.route.name), this.$t('form.delete_message.post'),
                ])
            ]);
            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(record.id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        /**
         * @vuese
         * Load data from database
         */
        loadDataFromBase() {
            if (!this.__isLogin()) {
                return;
            }
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.main + "/bookings", config)
                .then((response) => {
                    let bookings = response.data;
                    this.$emit('eventSetCountBookings', bookings.length);
                    let array = []
                    response.data.forEach(record => {
                        array.push(
                            {
                                expand: false,
                                id: record.id,
                                routeId: record.route_id,
                                stamp: record.stamp,
                                content: record.content,
                                conclusion: record.conclusion,
                                route: record.route,
                            }
                        );
                    });
                    // <--  -->
                    this.$set(this, 'bookings', array);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.delete(this.uri.main + "/bookings" + `/${record_id}`, config)
                .then((response) => {
                    let bookings = response.data;
                    this.$emit('eventSetCountBookings', bookings.length);
                    let array = []
                    response.data.forEach(record => {
                        array.push(
                            {
                                expand: false,
                                id: record.id,
                                routeId: record.route_id,
                                stamp: record.stamp,
                                content: record.content,
                                conclusion: record.conclusion,
                                route: record.route,
                            }
                        );
                    });
                    // <--  -->
                    this.$set(this, 'bookings', array);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        getDestinations(){
            if (this.__isNull(this.destinations)) {
                return [];
            }
            // <--  -->
            let destinations = [];
            // let name, subtitle;
            // this.destinations.forEach(destinations => {
            //     // <-- name -->
            //     name = destinations.meta.name;
            //     name = this.__isNull(destinations.locale) || this.__isNull(destinations.locale.name) ? name : destinations.locale.name;
            //     // <-- subtitle -->
            //     subtitle = destinations.meta.subtitle;
            //     subtitle = this.__isNull(destinations.locale) || this.__isNull(destinations.locale.subtitle) ? subtitle : destinations.locale.subtitle;
            //     // <--  -->
            //     destinations.push(
            //         {
            //             selected: destinations.selected,
            //             id: destinations.id,
            //             title: name,
            //             subtitle: subtitle,
            //             latlng: {
            //                 lat: destinations.content.coordinates.latitude,
            //                 lng: destinations.content.coordinates.longitude
            //             },
            //             experiences: destinations.experiences
            //         }
            //     );
            // });
            // <--  -->
            return destinations;
        }
    },
    filters: {},
    watch: {
        'hotLink.mode': {
            handler: function (mode, oldMode) {
                if (mode === 'bookings') {
                    switch (this.modeSize) {
                        case 'xs-sm':
                            this.loadDataFromBase();
                            break;
                        case 'md-xl':
                            this.loadDataFromBase();
                            break;
                    }
                }
            },
        },
    }
}
</script>

<style scoped>

</style>
