<template>
    <div>
        <b-card
            v-for="(record, index) in routes"
            class="mb-2 my-2 mr-1 experience-card border"
            no-body
            :header-bg-variant="getBackgroundVariant(index)"
            header-class="px-2 py-0"
            :header-text-variant="index % 2=== 0 ? 'white' : 'dark'"
            body-text-variant="secondary"
            footer-class="p-0"
            footer-border-variant="transparent"
        >
            <!-- head -->
            <template #header>
                <b-row @click="record.expand=!record.expand">
                    <!-- id, name -->
                    <b-col class="d-flex justify-content-start align-items-center">
                        {{record.name}}
                    </b-col>
                    <!-- buttons -->
                    <b-col cols="3" class="d-flex justify-content-end align-items-baseline py-1"
                           style="min-width: 130px;">
                        <div class="d-flex justify-content-end mx-0">
                            <!-- button show route -->
                            <b-button
                                class="py-0 button-header p-0 mr-1"
                                style="width: 35px"
                                @click.stop="showRoute(record)"
                            >
                                <b-icon-pin-map scale="0.7"/>
                            </b-button>
                            <!-- button booking route -->
                            <b-button
                                class="py-0 button-header p-0 mr-1"
                                :disabled="!bookingEnabled"
                                :title="!bookingEnabled ? $t('adventure.route.noLogin_message.book') : null"
                                style="width: 35px"
                                @click.stop="bookingRoute(index, record)"
                            >
                                <b-icon-book scale="0.8"/>
                            </b-button>
                            <!-- button collapse/expand -->
                            <b-button
                                class="py-0 button-header p-0"
                                style="width: 35px"
                                @click.stop="record.expand=!record.expand"
                            >
                                <b-icon
                                    :icon="record.expand ? 'three-dots-vertical' : 'three-dots'"
                                    scale="0.9"
                                />
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <!-- body -->
            <b-card-body class="px-0 py-1 adventure-card-body" v-show="record.expand">
                <!-- info -->
                <sider-route-info :record="record.info"/>
                <!-- contractor -->
                <sider-route-contractor v-if="!record.contractor.isNull" :record="record.contractor"/>
                <!-- content -->
                <sider-route-editor-text v-if="!record.content.isNull" :record="record.content"/>
                <!-- school excursion -->
                <sider-route-editor-text v-if="!record.schoolExcursion.isNull" :record="record.schoolExcursion"/>
                <!-- travel dates -->
                <sider-route-travel-dates v-if="!record.travelDates.isNull" :record="record.travelDates"/>
                <!-- transporter -->
                <sider-route-transporter v-if="!record.transporter.isNull" :record="record.transporter"/>
                <!-- insurance -->
                <sider-route-insurance v-if="!record.insurance.isNull" :record="record.insurance"/>
                <!-- pictures -->
                <sider-route-pictures v-if="record.pictures.pictures.length > 0" :record="record.pictures"/>
                <!-- Experiences -->
                <sider-route-experiences v-if="record.experiences.content.length > 0"
                                         :route="record.route"
                                         :record="record.experiences"
                                         :hot-link="hotLink"
                                         @eventShowRoute="eventShowRoute"
                                         @eventPopupOpenClose="experiencePopupOpenClose"
                />
                <!-- miscellaneous -->
                <sider-route-editor-text v-if="!record.miscellaneous.isNull" :record="record.miscellaneous"/>
                <!-- gtac -->
                <sider-route-editor-text v-if="!record.gtac.isNull" :record="record.gtac"/>
                <!-- prices: verjetno v footer-->
                <!--               prices-->    <!-- // TODO  -->

                <!-- booking -->
                <sider-route-booking
                    :button-variant="getBackgroundVariant(index)"
                    :route="record"
                    @eventRefreshBookings="refreshRouteBooking"
                />
            </b-card-body>
            <!-- footer -->
            <!--            <template #footer>
                            &lt;!&ndash; best adult price &ndash;&gt;
                            <div v-show="record.expand" style="height: 37px" class="bg-info-light p-1">
                                        {{record.expand}} BOOKING
            &lt;!&ndash;                    <div class="float-right">
                                    <div class="mb-0 d-inline mr-3 font-italic h5 text-secondary">
                                        Let's do it
                                        {{$t('adventure.sider_experience.best_adult_price')}}:
                                        &lt;!&ndash;                            <b>{{getBestAdultPrice}}</b>&ndash;&gt;
                                        999,66 €
                                    </div>
                                </div>&ndash;&gt;
                            </div>
                        </template>-->
        </b-card>
        <!-- modal -->
        <modal-route-booking
            :component-key="'routeBooking_' + bookingComponentKey"
            :route="bookingComponentRoute"
            @eventRefreshBookings="refreshRouteBooking"
        />
    </div>
</template>

<script>
import SiderRouteInfo from "@/components/_public/_adventure/sider/SiderRouteInfo";
import SiderRouteContractor from "@/components/_public/_adventure/sider/SiderRouteContractor";
import SiderRouteEditorText from "@/components/_public/_adventure/sider/SiderRouteEditorText";
import SiderRouteTravelDates from "@/components/_public/_adventure/sider/SiderRouteTravelDates";
import SiderRouteTransporter from "@/components/_public/_adventure/sider/SiderRouteTransporter";
import SiderRouteInsurance from "@/components/_public/_adventure/sider/SiderRouteInsurance";
import SiderRoutePictures from "@/components/_public/_adventure/sider/SiderRoutePictures";
import SiderRouteExperiences from "@/components/_public/_adventure/sider/SiderRouteExperiences";
import SiderRouteBooking from "@/components/_public/_adventure/sider/SiderRouteBooking";
import ModalRouteBooking from "@/components/_public/_adventure/modal/ModalRouteBooking";

export default {
    name: "SiderRoutes",
    components: {
        ModalRouteBooking,
        SiderRouteBooking,
        SiderRouteExperiences,
        SiderRoutePictures,
        SiderRouteInsurance,
        SiderRouteTransporter,
        SiderRouteTravelDates,
        SiderRouteEditorText,
        SiderRouteContractor,
        SiderRouteInfo
    },
    props: {
        /**
         * Routes with experiences
         */
        pRoutes: {
            type: Array,
            default() {
                return []
            }
        },
        // Hot link among Adventure, siderExperience and LeafletMap
        hotLink: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            bookingComponentKey: this.__createUniqueKey(0),
            bookingComponentRoute: {},

            bookingIndex: 0,
            routes: [],
        }
    },
    mounted() {
        if (this.routes.length === 0) {
            this.setRoutes();
        }
    },
    methods: {
        getBackgroundVariant(index) {
            return index % 2 === 0 ? 'secondary' : 'primary';
        },
        // <-- routes -->
        setRoutes() {
            let routes = [];
            this.pRoutes.forEach(record => {
                let name = record.meta.name;
                if (!this.__isNull(record.locale.name)) {
                    name = record.locale.name;
                }
                // <-- subtitle -->
                let subtitle = record.meta.subtitle;
                if (!this.__isNull(record.locale.subtitle)) {
                    subtitle = record.locale.subtitle;
                }
                // <-- subtitle -->
                let description = record.meta.description;
                if (!this.__isNull(record.locale.description)) {
                    description = record.locale.description;
                }
                // <-- contractor -->
                let contractorName, street, postOffice, city, region, state, continent;
                if (!this.__isNull(record.contractor)) {
                    contractorName = this.__isNull(record.contractor.name.name) ? record.contractor.name.name_full : record.contractor.name.name;
                    street = record.contractor.address.street;
                    postOffice = record.contractor.address.postOffice;
                    city = record.contractor.address.city;
                    region = record.contractor.address.region;
                    state = record.contractor.address.state;
                    continent = record.contractor.address.continent;
                }
                // <--  -->
                routes.push(
                    {
                        expand: false,
                        route: {
                            id: record.id,
                            stamp: record.stamp,
                        },
                        name: name,
                        info: {
                            expand: false,
                            title: this.$t('form.info'),
                            id: record.id,
                            stamp: record.stamp,
                            subtitle: subtitle,
                            description: description,
                        },
                        contractor: {
                            expand: false,
                            title: this.$t('adventure.contractor'),
                            isNull: this.__isNull(record.contractor),
                            name: contractorName,
                            street: street,
                            postOffice: postOffice,
                            city: city,
                            region: region,
                            state: state,
                            continent: continent
                        },
                        content: {
                            expand: false,
                            title: this.$t('form.content'),
                            content: record.locale.content,
                        },
                        schoolExcursion: {
                            expand: false,
                            title: this.$t('adventure.school_excursion'),
                            content: record.locale.schoolExcursion,
                        },
                        travelDates: {
                            expand: false,
                            title: this.$tc('adventure.travel_date', 0),
                            content: record.travelDates,
                        },
                        transporter: {
                            expand: false,
                            title: this.$tc('adventure.transporter', 0),
                            content: record.transporter,
                        },
                        insurance: {
                            expand: false,
                            title: this.$tc('adventure.insurance', 0),
                            content: record.insurance,
                        },
                        pictures: {
                            expand: false,
                            title: this.$t('form.tab.picture'),
                            componentKey: this.__createUniqueKey(record.id),
                            pictures: record.pictures,
                        },
                        experiences: {
                            expand: true,
                            title: this.$t('adventure.sider_menu.experience'),
                            content: record.experiences,
                        },
                        miscellaneous: {
                            expand: false,
                            title: this.$t('form.miscellaneous'),
                            content: record.locale.miscellaneous,
                        },
                        gtac: {
                            expand: false,
                            title: this.$tc('business.gtac', 1),
                            content: record.locale.gtac,
                        },
                    }
                );
            });
            // <--  -->
            this.$set(this, 'routes', routes);
        },
        eventShowRoute(routeId) {
            let record = null;
            this.routes.every(v => {
                if (v.route.id === routeId) {
                    record = v;
                    return false;
                }
                return true;
            });
            this.showRoute(record);
        },
        showRoute(record) {
            if (record === null) {
                return;
            }
            // <-- route -->
            let route = {
                id: record.route.id,
                stamp: record.route.stamp,
            };
            // <-- routing -->
            let routes = [];
            record.experiences.content.forEach(content => {
                routes.push({
                    coordinates: content.destination.coordinates,
                    destination: {
                        id: content.destination.id,
                        name: content.destination.name,
                        subtitle: content.destination.subtitle
                    },
                    experience: {
                        id: content.experience_id
                    }
                });
            });
            // <--  -->
            this.hotLink.route = route;
            this.hotLink.routing = routes;

            this.$emit('eventShowRoute', {routeName: record.name});
        },
        // <-- bookings -->
        bookingRoute(index, record) {
            this.bookingComponentRoute = record;
            this.$bvModal.show('routeBooking_' + this.bookingComponentKey);
        },
        refreshRouteBooking(data) {
            this.$emit('eventRefreshBookings', this.__isNull(data) ? 0 : data.length);
        },
        // <-- popup -->
        experiencePopupOpenClose(data) {
            // <-- hotlink.destinationId != null -->
            this.hotLink.destinationId = null;
            // <-- close if it is open -->
            if (this.hotLink.popupOpen && this.hotLink.experienceId === data.experienceId) {
                this.$set(this.hotLink, 'experienceId', null);
                this.$set(this.hotLink, 'popupOpen', false);
                this.$emit('eventPopupOpenClose')
                return;
            }
            // <--  -->
            this.$set(this.hotLink, 'experienceId', data.experienceId);
            this.$set(this.hotLink, 'popupOpen', true);
            this.$emit('eventPopupOpenClose')
        },
    },
    computed: {
        /**
         * @vuese
         * Check if user login for mode bookings
         */
        bookingEnabled() {
            return this.$root.mx_isLogin;
        },
    },
    filters: {},
    watch: {
        'pRoutes': {
            handler: function (value, oldValue) {
                if (this.routes.length === 0) {
                    this.setRoutes();
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
