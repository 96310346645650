<template>
    <div>
        <b-card
            class="mb-3 pt-3 px-3 pb-2 experience-card shadow"
            no-body
            header-bg-variant="secondary"
            header-class="p-2"
            header-text-variant="white"
            footer-class="p-0"
            footer-border-variant="transparent"
        >
            <b-row no-gutters class="font-italic h5 text-secondary">
                <b-col cols="6">
                    {{$t('cart.subtotal')}}:
                </b-col>
                <b-col cols="6" class="text-right">
                    621,00 €
                </b-col>
                <b-col cols="6">
                    {{$t('cart.tax')}}:
                </b-col>
                <b-col cols="6" class="text-right">
                    62,10 €
                </b-col>
                <b-col cols="12">
                    <b-button
                        size="sm"
                        class="mt-2"
                        @click="openModalAddSumAdventure()"
                    >
                        {{$t('form.add_plus')}}
                    </b-button>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
                <b-col cols="6">
                    {{$t('cart.total')}}:
                </b-col>
                <b-col cols="6" class="text-right">
                    <b>
                        682,10 €
                    </b>
                </b-col>
                <b-col cols="12" class="mt-2 text-right">
                    <b-button
                        :title="$t('adventure.sider_experience.book_title')"
                    >
                        {{$t('adventure.sider_experience.book')}}
                    </b-button>

                </b-col>
            </b-row>
        </b-card>
        <!-- modal Search Adventure -->
        <modal-add-sum-adventure
            :component-key="componentKey"
            :modal-bus.sync="modalBus"
        >
        </modal-add-sum-adventure>
        <!--            :parent-records="getPriceListSeasons"-->
    </div>
</template>

<script>
import Vue from "vue";
import ModalAddSumAdventure from "@/components/_public/_adventure/modal/ModalAddSumAdventure";

// @group ADVENTURE
export default {
    name: "CardSumAdventure",
    components: {ModalAddSumAdventure},
    props: {},
    data() {
        return {
            componentKey: null,
            // <-- modal AddSumAdventure -->
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.componentKey = this.__randomKey();
    },
    methods: {
        // <-- AddSumAdventure -->
        openModalAddSumAdventure() {
            this.modalBus.$emit('modalOpen', 0);
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
