<template>
    <b-card
        class="mb-3 experience-card shadow"
        no-body
        header-bg-variant="secondary"
        header-class="p-2"
        header-text-variant="white"
        footer-class="p-0"
        footer-border-variant="transparent"
        :class="data.selected ? 'experience-select' :'experiences'"
        @click="experienceSelect()"
    >
        <!-- header -->
        <template #header>
            <b-row>
                <b-col>
                    {{data.header}}
                </b-col>
                <b-col cols="2">
                    <div class="float-right">
                        #{{data.id}}
                    </div>
                </b-col>
            </b-row>
        </template>
        <!-- body -->
        <b-row no-gutters>
            <!-- picture -->
            <b-col class="experience-card-image" cols="4">
                <!-- no image -->
                <b-img v-if="getPictures === null" class="rounded-0" src="@/assets/images/default.jpg"/>
                <!-- carousel -->
                <b-carousel
                    id="experience-carousel"
                    v-if="getPictures !== null"
                    v-model="slide"
                    :interval="0"
                    background="#ababab"
                    class="img-container mt-1"
                    controls
                    fade
                    indicators
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                >
                    <b-carousel-slide v-for="picture in getPictures">
                        <template #img>
                            <b-img
                                class="rounded-0"
                                :src="picture.image.src"
                                style="max-height: 158px"
                            />
                        </template>
                    </b-carousel-slide>
                </b-carousel>
            </b-col>
            <!-- content -->
            <b-col cols="8" class="experience-card-text">
                <!-- organisation type -->
                <b-card-body class="px-2 py-1">
                    <b-row no-gutters>
                        <b-col cols="12" class="experience-card-body">
                            <!-- experience name -->
                            <b-card-title>{{data.name}}</b-card-title>
                            <!-- subtitle -->
                            <b-card-sub-title>{{data.subtitle}}</b-card-sub-title>
                            <!-- duration -->
                            <p class="text-secondary mt-3 mb-0">
                                <b-icon-clock></b-icon-clock>
                                <b> {{data.duration}}</b>
                            </p>
                            <!-- categories, types -->
                            <b-card-text class="text-primary my-1" style="clear: both">
                            <span v-for="categoryTypes in getCategoriesTypes">
                                <!-- categories -->
                                <b-badge
                                    variant="secondary"
                                    style="font-size: 9px"
                                >
                                    {{categoryTypes.category}}
                                </b-badge>
                                <!-- types -->
                                <b-badge v-for="(type, index) in categoryTypes.types"
                                         variant="primary"
                                         style="font-size: 9px"
                                         :class="index === categoryTypes.types.length-1 ? 'ml-1 mr-2' : 'ml-1'"
                                >
                                    {{type}}
                                </b-badge>
                            </span>
                            </b-card-text>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-col>
        </b-row>
        <!-- show only if isAdventureMember -->
        <b-row class="p-3" v-if="isAdventureMember">
            <b-col cols="6">
                <p class="mb-0">
                    <b-icon-calendar2-check></b-icon-calendar2-check>
                    # torek, 22. 3. 2022
                </p>
                <p class="mb-0">
                    <b-icon-alarm></b-icon-alarm>
                    # 16:00-18:00
                </p>
                <p class="mb-0">
                    <b-icon-people></b-icon-people>
                    # 8
                </p>
            </b-col>
            <b-col cols="6" class="text-right">
                <div class="font-italic h5 text-secondary">
                    <b># 105,56 €</b>
                </div>
                <b-button size="sm" @click.stop="popupHotOpenClose()">
                    <b-icon-geo-alt-fill/>
                </b-button>
                <b-button size="sm" class="ml-2" variant="danger" @click="remove(adventure.key)">
                    <b-icon-trash-fill/>
                </b-button>
            </b-col>
        </b-row>
        <!-- footer -->
        <template #footer v-if="!isAdventureMember">
            <!-- best adult price -->
            <div style="height: 37px" class="bg-info-light p-1">
                <div class="float-right">
                    <div class="mb-0 d-inline mr-3 font-italic h5 text-secondary" v-if="!__isNull(getBestAdultPrice)">
                        {{$t('adventure.sider_experience.best_adult_price')}}:
                        <b>{{getBestAdultPrice}}</b>
                    </div>
                    <b-button size="sm" @click.stop="popupHotOpenClose()">
                        <b-icon-geo-alt-fill/>
                    </b-button>
                </div>
            </div>
        </template>
    </b-card>
</template>

<script>
//@group LEAFLET
export default {
    name: "CardExperience",
    components: {},
    props: {
        // Data for destinations and experience
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        // Define is CardExperience is included in adventure
        isAdventureMember: {
            type: Boolean,
            default: false
        },

        // TODO @Deprecated
        // Destinations points included in adventures
        // @Deprecated
        adventure: {
            type: Object,
            default() {
                return {}
            }
        },
        // Available destinations points with experiences
        // @Deprecated
        destinations: {
            type: Array,
            default() {
                return []
            }
        },
        // Hot link among Adventure, siderExperience and LeafletMap
        hotLink: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            slide: 0,
        }
    },
    mounted() {
    },
    methods: {
        // <-- carousel -->
        onSlideStart(slide) {
            // this.sliding = true;
        },
        onSlideEnd(slide) {
            // this.sliding = false;
        },
        // <--  -->
        experienceSelect() {
            /**
             * @vuese
             * Fire when select experience
             * Select experience, select marker in map.
             * @arg experience.id
             */
            this.$emit('eventExperienceSelect', this.data.id);
        },
        remove(adventureKey) {
            // Fire when remove experience from adventure
            // @arg adventure.key
            this.$emit('eventExperienceRemove', adventureKey);
        },
        popupHotOpenClose() {
            /**
             * @vuese
             * Fire when select popupOpen
             * Select experience, select marker in map and open marker popup.
             * @arg experience.id
             */
            this.$emit('eventPopupOpenClose', this.data.id);
        }
    },
    computed: {
        getPictures() {
            if (this.__isNull(this.data.pictures)) {
                return null;
            }
            return this.data.pictures;
        },
        getCategoriesTypes() {
            if (this.__isNull(this.data.experienceTypes)) {
                return null;
            }
            let record = this.data.experienceTypes;
            // <-- categories -->
            let categories = [];
            record.forEach(value => {
                categories.push(value.category);
            });
            categories = this.__uniqueArray(categories);
            categories = this.__sortArray(categories);
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                record.forEach(record => {
                    if (category === record.category) {
                        record.types.forEach(type => {
                            types.push(type);
                        });
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <--  -->
            return categoriesTypes;
        },
        getBestAdultPrice(){
            if(this.__isNull(this.data.bestAdultPrice)){
                return null;
            }
            // <--  -->
            let bap =this.data.bestAdultPrice;
            // <--  -->
            return `${this.__getDecimalLocale(bap.amount)} ${bap.currency}`;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.experience-select {
    min-height: 80px;
    border: #6b8b30 2px solid;
    border-radius: 0.25rem;
    background-color: rgba(171, 208, 84, 0.5);
}

.card-footer {
    border-top: transparent !important;
}
</style>
