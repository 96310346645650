<template>
    <div>
        <!-- button -->
            <b-form-group class="text-center mb-1 mt-2">
                <b-button
                    :disabled="isBookingDisabled"
                    :variant="buttonVariant"
                    @click="modalOpen()"
                >
                    <b-icon-book scale="0.8"/>
                    {{$tc('adventure.sider_experience.booking', 1)}}
                </b-button>
                <b-form-invalid-feedback :state="!isBookingDisabled">
                    <div v-html="$t('adventure.route.noLogin_message.book')"/>
                </b-form-invalid-feedback>
            </b-form-group>
        <!-- modal -->
        <modal-route-booking
            :component-key="'routeBooking_' + this.componentKey"
            :route="route"
            @eventRefreshBookings="eventRefreshBookings"
        />
    </div>
</template>

<script>
import ModalRouteBooking from "@/components/_public/_adventure/modal/ModalRouteBooking";

export default {
    name: "SiderRouteBooking",
    components: {ModalRouteBooking},
    props: {
        buttonVariant: {
            type: String,
            default: 'primary'
        },
        // Data for booking the route
        route: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            componentKey: this.__createUniqueKey(0),
        }
    },
    mounted() {

    },
    methods: {
        modalOpen() {
            this.$bvModal.show('routeBooking_' + this.componentKey);
        },
        eventRefreshBookings(data){
            this.$emit('eventRefreshBookings', data);
        },
    },
    computed: {
        isBookingDisabled() {
            return !this.$root.mx_isLogin;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
