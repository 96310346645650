import { render, staticRenderFns } from "./ModalAddSumAdventure.vue?vue&type=template&id=13e10d54&scoped=true&"
import script from "./ModalAddSumAdventure.vue?vue&type=script&lang=js&"
export * from "./ModalAddSumAdventure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e10d54",
  null
  
)

export default component.exports